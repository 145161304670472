import React, { Component } from 'react'
import $ from 'jquery'
import Style from '../../Style Page/style_page'
import Form from '../../Forms/form'
import Input from '../../Forms/input'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import TextArea from '../../Forms/textArea'
import Select from '../../Forms/select'
import Footer from '../../Footer/footer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import Alert from '../../Alerts/alert'
import { nivel_pilula, categoria_pilula, edit_pilula, isAgenda, put_pilula } from '../../Services/Pilula'
import { changeNivel, changeCategoria, changeMsgNotificacao, changeTitulo, changeTituloNotificacao, changeTipo, changePontos, changePontosVisualizacao, changeTexto, changePergunta, changeRespostaA, changeRespostaB, changeRespostaC, changeRespostaD, changeRespostaCorreta, changeFeedBackCorreto, changeFeedBackErrado, post, changeImageIcone, changeImageFundo, editPilula, changeVideo } from './Actions/actions'
import fonts from "../../../fonts/fonts.js";


class editar_pilulas extends Component {
    state = { 
        nivelPilula: [], 
        categoriaPilula: [], 
        imageIcone: [], 
        imageFundo: [], 
        error: '', 
        texto: '', 
        success: '',
        
        loading: false,
        errorTitle: false,
        errorType: false,
        errorCategory: false,
        errorLevel: false,
        errorPoints: false,
        errorPointsView: false,
        errorURL: false,
    }

    formValidate = () => {
        return (
            this.titleValidate()      &&
            this.typeValidate()       &&
            this.categoryValidate()   &&
            this.levelValidate()      &&
            this.pointsValidate()     &&
            this.pointsViewValidate() &&
            (this.props.tipo === 'video' ? this.videoValidate() : true)
        );
            
    }

    setErrors = () => {
        if(!this.titleValidate()){
            this.setState({errorTitle : true});
        }else
            this.setState({errorTitle : false});

        if(!this.typeValidate())
            this.setState({errorType : true});
        else
            this.setState({errorType : false});

        if(!this.categoryValidate())
            this.setState({errorCategory : true});
        else
            this.setState({errorCategory : false});

        if(!this.levelValidate())
            this.setState({errorLevel : true});
        else
            this.setState({errorLevel : false});

        if(!this.pointsValidate())
            this.setState({errorPoints : true});
        else
            this.setState({errorPoints : false});

        if(!this.pointsViewValidate())
            this.setState({errorPointsView : true});
        else
            this.setState({errorPointsView : false});

        if(this.props.tipo === 'video'){
            if(!this.videoValidate())
                this.setState({ errorURL : true});
            else
                this.setState({ errorURL : false });
        }
    }

    titleValidate = () => {
        if(this.props.titulo == '')
            return false;
        return true;
    }

    typeValidate = () => {
        if(this.props.tipo == ''){
            return false;
        }
        return true;
    }

    categoryValidate = () => {
        if(this.props.categoria == ''){
            return false;
        }
        return true;
    }

    levelValidate = () => {
        if(this.props.nivel == '') {
            return false;
        }
        return true;
    }

    pointsValidate = () => {
        let regex = new RegExp('^[0-9]*$');

        if(regex.test(this.props.pontos)){
            return true;
        }
        return false;
    }


    pointsViewValidate = () => {
        const categoriaId = this.props.categoria;
        const pontosVisualizacao = this.props.pontosVisualizacao || 0;
    
        // Quando categoria_id é 4 (Material Complementar), permitir zero
        if (categoriaId == 4) {
            let regex = new RegExp('^[0-9]+$');
            return regex.test(pontosVisualizacao);
        } else {
            let regex = new RegExp('^[1-9][0-9]*$');
            return regex.test(pontosVisualizacao);
        }
    }

    videoValidate = () => {
        if(this.props.video == '' || !this.props.video) {
            return false;
        }
        return true;
    }

    componentDidMount() {

        const id = this.props.match.params.id
        edit_pilula(id).then(resp => (
            this.setState({ texto: resp.data[0].texto }),
            this.props.editPilula(
                resp.data[0]
            )



        )).then(resp => (
            isAgenda(id).catch(e => { this.setState({ error: e.response.data.error }) })
        ))

            .then(resp => (
                nivel_pilula()
                    .then(resp => (this.setState({ nivelPilula: resp.data })))
            ))
            .then(resp => (
                categoria_pilula(this.props.empresa_id)
                    .then(resp => (this.setState({ categoriaPilula: resp.data })))
            ))



    }
    renderText() {
        const script = document.createElement("script")


        script.src = `../js/textarea.js`
        script.async = true;
        document.body.appendChild(script);
    }

    onChangeIcone = event => {
        const file = event.target.files[0]
        this.setState({ imageIcone: file })


    }

    onChangeFundo = event => {
        const file = event.target.files[0]
        this.setState({ imageFundo: file })


    }

    changeTexto = event => {
        const texto = event.target.value
        this.setState({ texto: texto })

    }


    //Mapeando o objeto com os níveis das pílulas
    nivelPilula() {
        const nivelPilula = this.state.nivelPilula || [];
        return nivelPilula.map(desc => (
            <option key={desc.id} value={desc.id}>{desc.descricao}</option>
        ))
    }

    //Mapeando o objeto com as categorais da pílulas
    categoriaPilula() {
        const categoriaPilula = this.state.categoriaPilula || [];
        return categoriaPilula.map(desc => (
            <option key={desc.id} value={desc.id}>{desc.nome}</option>
        ))

    }
    handleForm = (e) => {
        e.preventDefault();
        this.setErrors();

        if(this.formValidate() ){
            const texto = $('.textarea').val();
            // const data = new FormData();
            const id = this.props.match.params.id
            // data.append('tipo',this.props.tipo)
            // data.append('categoria_id',this.props.categoria)
            // data.append('empresa_id',this.props.empresa_id)
            // data.append('titulo',this.props.titulo)
            // data.append('push_titulo',this.props.tituloNotificacao)
            // data.append('push_mensagem', this.props.msgNotificacao)
            // data.append('audio',this.state.audio)
            // data.append('push_big_picture',this.state.imageFundo)
            // data.append('nivel',this.props.nivel)
            // data.append('pontos',this.props.pontos)
            // data.append('texto',texto)
            // data.append('pergunta',this.props.pergunta)
            // data.append('opcao_a',this.props.respostaA)
            // data.append('opcao_b',this.props.respostaB)
            // data.append('opcao_c',this.props.respostaC)
            // data.append('opcao_d',this.props.respostaD)
            // data.append('opcao_correta',this.props.respostaCorreta)
            // data.append('feedback_correto',this.props.feedBackCorreto)
            // data.append('feedback_errado',this.props.feedBackErrado)
            // data.append('usuario_id',this.props.usuario_id) 
            // data.append('video',this.props.video || '') 
            const data = {
                tipo: this.props.tipo,
                categoria_id: this.props.categoria,
                empresa_id: this.props.empresa_id,
                titulo: this.props.titulo,
                push_titulo: this.props.tituloNotificacao,
                push_mensagem: this.props.msgNotificacao,
                audio: this.state.audio,
                push_big_picture: this.state.imageFundo,
                nivel: this.props.nivel,
                pontos: this.props.pontos,
                texto: texto,
                pergunta: this.props.pergunta,
                opcao_a: this.props.respostaA,
                opcao_b: this.props.respostaB,
                opcao_c: this.props.respostaC,
                opcao_d: this.props.respostaD,
                opcao_correta: this.props.respostaCorreta,
                feedback_correto: this.props.feedBackCorreto,
                feedback_errado: this.props.feedBackErrado,
                usuario_id: this.props.usuario_id,
                video: this.props.video,
                pontosVisualizacao: +this.props.pontosVisualizacao,

            }

            // put_pilula(data,id,{headers: {
            //     "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            // }})
            put_pilula(data, id)
                .then(resp => { this.setState({ success: "Dados editados com sucesso !" }) })
                .then(resp => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 3000)
                })
                .catch(e => {
                    this.setState({
                        loading: false,
                        success: '',
                        error: e.response.data.error
                    })

                    setTimeout(() => {
                        this.setState({ loading: false, success: '', error: '' })
                    }, 4500)
                })
        } else {
            this.setErrors();
        }
    }
    consoel
    render() {
        return (
            <div className="wrapper">
                <Header />
                <Sidebar />

                <Style title="Edição de Pílulas" subtitle="Editar Pílula">
                    {this.state.error ? (
                        <Alert type="danger" hidden={true}>{this.state.error}</Alert>

                    ) : ('')}
                    {this.state.success ? (
                        <Alert type="success" hidden={true}>{this.state.success}</Alert>

                    ) : ('')}
                    <Form onSubmit={this.handleForm}>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    label="Título"
                                    value={this.props.titulo}
                                    onChange={this.props.changeTitulo}
                                    icon="fa-assistive-listening-systems"
                                    name="titulo"
                                    type="text"
                                    placeholder="Digite o título da pílula"
                                    disabled={this.state.error ? (true) : (false)}
                                />
                                <p style={{color: 'red'}}>{this.state.errorTitle ? '*O campo Titulo é obrigatótio' : ''}</p>
                            </div>
                            {/* <div className="col-md-6">
                                <Input 
                                    label="Título da Notificação" 
                                    value={this.props.tituloNotificacao} 
                                    onChange={this.props.changeTituloNotificacao} 
                                    icon="fa-assistive-listening-systems" 
                                    name="titulo_notificacao" 
                                    type="text" 
                                    placeholder="Digite o título de notificação da pílula"
                                    disabled={this.state.error ? (true):(false)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input 
                                    label="Mensagem da Notificação" 
                                    value={this.props.msgNotificacao} 
                                    onChange={this.props.changeMsgNotificacao} 
                                    icon="fa-assistive-listening-systems" 
                                    name="mensagem_notificacao" 
                                    type="text" 
                                    placeholder="Digite a mensagem da notificação"
                                    disabled={this.state.error ? (true):(false)}
                                />
                            </div>

                        </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <Select
                                    onChange={this.props.changeTipo}
                                    value={this.props.tipo}
                                    label="Tipo"
                                    disabled={this.state.error ? (true) : (false)}
                                >
                                    <option>Selecione um tipo</option>
                                    <option value="texto">Texto</option>
                                    <option value="audio">Áudio</option>
                                    <option value="video">Vídeo</option>
                                </Select>
                                <p style={{color: 'red'}}>{this.state.errorType ? '*O campo Tipo é obrigatótio' : ''}</p>

                            </div>
                            <div className="col-md-3">
                                <Select
                                    onChange={this.props.changeCategoria}
                                    value={this.props.categoria}
                                    label="Categoria"
                                    disabled={this.state.error ? (true) : (false)}
                                >
                                    <option>Selecione a categoria</option>
                                    {this.categoriaPilula()}
                                </Select>
                                <p style={{color: 'red'}}>{this.state.errorCategory ? '*O campo Categoria é obrigatótio' : ''}</p>

                            </div>
                            <div className="col-md-3">
                                <Select
                                    onChange={this.props.changeNivel}
                                    value={this.props.nivel}
                                    label="Nível"
                                    disabled={this.state.error ? (true) : (false)}
                                >
                                    <option>Selecione o nível</option>
                                    {this.nivelPilula()}
                                </Select>
                                <p style={{color: 'red'}}>{this.state.errorLevel ? '*O campo Nível é obrigatótio' : ''}</p>

                            </div>
                            <div className="col-md-3">
                                <Input
                                    onChange={this.props.changePontos}
                                    value={this.props.pontos}
                                    label="Pontos"
                                    icon="fa-balance-scale"
                                    name="pontos"
                                    type="number"
                                    placeholder="Digite a pontuação"
                                    disabled={this.state.error ? (true) : (false)}
                                />
                                <p style={{color: 'red'}}>{this.state.errorPoints ? '*O campo Pontos deve ser um número positivo' : ''}</p>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-md-3">
                                <Input
                                    onChange={this.props.changePontosVisualizacao}
                                    value={this.props.pontosVisualizacao}
                                    label="Pontos de visualização"
                                    icon="fa-balance-scale"
                                    name="pontosVisualizacao"
                                    type="number"
                                    disabled={this.state.error ? (true) : (false)}
                                    step={1}
                                    min={0}
                                    maxLength={'9'}
                                    placeholder="Digite a pontuação por visualização"
                                />
                                <p style={{color: 'red'}}>{this.state.errorPointsView ? '*O campo Pontos de Visualização deve ser um inteiro maior que 0' : ''}</p>
                            </div>
                        </div>
                        {this.state.texto ? (

                            <div className="row">
                                <div className="col-md-12">
                                    <TextArea
                                        label="Texto"
                                        placeholder="Digite o texto da pílula"
                                        disabled={this.state.error ? (true) : (false)}
                                        onChange={this.changeTexto}
                                        value={this.state.texto}
                                        id="textarea"
                                    />
                                </div>
                            </div>

                        ) : ('')}
                        {this.renderText()}

                        <br></br>

                        {this.props.tipo == 'video' ? (
                            <div className="row">

                                <div className="col-md-6">
                                    <Input label="Vídeo" disabled={this.state.error ? (true) : (false)} value={this.props.video} onChange={this.props.changeVideo} icon="fa-assistive-listening-systems" name="video" type="text" placeholder="Cole aqui o link do vídeo" />
                                    <p style={{color: 'red'}}>{this.state.errorURL ? '*O campo Vídeo é obrigatório' : ''}</p>
                                </div>
                            </div>

                        ) : ('')}
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="text"
                                    icon="fa-question"
                                    value={this.props.pergunta}
                                    onChange={this.props.changePergunta}
                                    label="Pergunta"
                                    placeholder="Digite a pergunta ..."
                                    disabled={this.state.error ? (true) : (false)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Input
                                    type="text"
                                    icon="fa-dice-d6"
                                    value={this.props.respostaA}
                                    onChange={this.props.changeRespostaA}
                                    label="Resposta A"
                                    placeholder="Digite a resposta A ..."
                                    disabled={this.state.error ? (true) : (false)}
                                />
                            </div>
                            <div className="col-md-6">
                                <Input
                                    type="text"
                                    icon="fa-dice-d6"
                                    value={this.props.respostaB}
                                    onChange={this.props.changeRespostaB}
                                    label="Resposta B"
                                    placeholder="Digite a resposta B ..."
                                    disabled={this.state.error ? (true) : (false)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Input
                                    type="text"
                                    icon="fa-dice-d6"
                                    value={this.props.respostaC}
                                    onChange={this.props.changeRespostaC}
                                    label="Resposta C"
                                    placeholder="Digite a resposta C ..."
                                    disabled={this.state.error ? (true) : (false)}
                                />
                            </div>
                            <div className="col-md-6">
                                <Input
                                    type="text"
                                    icon="fa-dice-d6"
                                    value={this.props.respostaD}
                                    onChange={this.props.changeRespostaD}
                                    label="Resposta D"
                                    placeholder="Digite a resposta D ..."
                                    disabled={this.state.error ? (true) : (false)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Select
                                    onChange={this.props.changeRespostaCorreta}
                                    value={this.props.respostaCorreta}
                                    label="Resposta Correta"
                                    disabled={this.state.error ? (true) : (false)}
                                >
                                    <option>Selecione a resposta correta</option>
                                    <option>A</option>
                                    <option>B</option>
                                    <option>C</option>
                                    <option>D</option>
                                </Select>
                            </div>
                            <div className="col-md-4">
                                <Input
                                    onChange={this.props.changeFeedBackCorreto}
                                    label="Feedback Correto"
                                    value={this.props.feedBackCorreto}
                                    type="text"
                                    icon="fa-smile"
                                    disabled={this.state.error ? (true) : (false)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Input
                                    onChange={this.props.changeFeedBackErrado}
                                    label="Feedback Errado"
                                    value={this.props.feedBackErrado}
                                    type="text"
                                    icon="fa-frown"
                                    disabled={this.state.error ? (true) : (false)}
                                />
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button disabled={this.state.error ? (true) : (false)} type="submit" className="btn btn-primary" style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Salvar</button>
                            <Link to="/pilulas" type="button" className="btn btn-secondary" style={{ marginLeft: '10px' }}>Cancelar</Link>
                        </div>
                    </Form>
                </Style>

                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => ({

    titulo: state.pilula.titulo,
    tituloNotificacao: state.pilula.tituloNotificacao,
    tipo: state.pilula.tipo,
    pontos: state.pilula.pontos || '',
    pontosVisualizacao: state.pilula.pontosVisualizacao,
    pergunta: state.pilula.pergunta,
    respostaA: state.pilula.respostaA,
    respostaB: state.pilula.respostaB,
    respostaC: state.pilula.respostaC,
    respostaD: state.pilula.respostaD,
    respostaCorreta: state.pilula.respostaCorreta,
    feedBackCorreto: state.pilula.feedBackCorreto,
    feedBackErrado: state.pilula.feedBackErrado,
    imageIcone: state.pilula.imageIcone,
    imageFundo: state.pilula.imageFundo,
    nivelPilula: state.pilula.nivelPilula,
    categoriaPilula: state.pilula.categoriaPilula,
    empresa_id: state.auth.user.empresa_id,
    msgNotificacao: state.pilula.msgNotificacao,
    categoria: state.pilula.categoria,
    nivel: state.pilula.nivel,
    usuario_id: state.auth.user.id_usuario,
    texto: state.pilula.texto,
    video: state.pilula.video

});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ changeNivel, changeCategoria, changeMsgNotificacao, changeTitulo, changeTituloNotificacao, changeTipo, changePontos, changePontosVisualizacao, changeTexto, changePergunta, changeRespostaA, changeRespostaB, changeRespostaC, changeRespostaD, changeRespostaCorreta, changeFeedBackCorreto, changeFeedBackErrado, post, changeImageIcone, changeImageFundo, editPilula, changeVideo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(editar_pilulas)
